<template>
  <div>
    <nav class="navbar navbar-dark bg-purple main-menu">
      <router-link :to="{name:'dashboard'}" class="navbar-brand spacing-mobile text-uppercase d-sm-block">
        <img class="logo-k" src="@/assets/img/k.jpg" />
      </router-link>
      <div class="search-main fill-space ml-md-3" v-if="!isMobileOnly()">
        <div class="d-flex ">
          <div class="input-group main-input">
            <div class="input-group-prepend">
              <span class="input-group-text text-muted"> <fa icon="search"/></span>
            </div>
            <input @focus="openModal" type="text" v-model="query" class="form-control text-muted searchButton"
                   aria-label="Text input with dropdown button " placeholder="Recherche rapide">
          </div>
          <div class="input-group">
            <select v-model="filter" class="text-muted form-control">
              <option value="tout">Tout</option>
              <option value="simulations">Simulation</option>
              <option value="societes">Raison sociale</option>
              <option value="contrats">Contrat</option>
            </select>
            <div class="input-group-append">
              <button class="btn btn-light-red d-flex align-items-center justify-content-center " type="button">
              <template v-if="loader">
                  <div class="spinner-border text-light" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
              </template>
              <template v-else>
                  <fa icon="search-plus" />
              </template>
              </button>
            </div>
          </div>
        </div>
        <div class="position-absolute clickOut" @click="closeModal" v-show="drop"></div>
        <div class="position-relative" v-show="drop">
          <div class="position-absolute containerSearch">
            <div class="d-flex" v-if="!this.simulations && !this.client && !this.contracts">
              <div class="w-100">
                <div class="card d-flex justify-content-center align-items-center text-muted h-100" v-if="!this.simulations?.length">
                  <fa icon="ban" class="mr-2"></fa>
                  Aucune résultat trouvé
                </div>
              </div>
            </div>
            <div class=" d-flex" v-if="this.filter === 'simulations' || (this.filter === 'tout' && this.simulations?.length)">
              <div class="background-grey d-flex align-items-center justify-content-center info-div info-div-border">
                <p class="text-muted">Simulation</p>
              </div>+
              <div class="w-100">
                <div class="card d-flex justify-content-center align-items-center text-muted h-100" v-if="!this.simulations?.length">
                  <fa icon="ban" class="mr-2"></fa>
                  Aucune Simulation trouvé
                </div>
                <div v-for="simulation in simulations" :key="simulation.id">
                  <router-link :to="{name:'custommerFunding', params: {id: simulation.societe.id, idFunding: simulation.rowid}}" @click="closeModal">
                    <div class="card w-100 d-flex">
                      <div class="d-flex justify-content-center align-items-center pr-2">
                        <fa :icon="informations[simulation.accord]?.ifa" :style="{'color': informations[simulation.accord]?.colorhex ?? 'grey' }" class="fa-2x fa-fw"/>
                      </div>
                      <div class="card-body">
                        <h5 class="font-weight-bolder">{{ simulation.societe.name }} - {{ simulation.reference }}</h5>
                        <p class="card-text text-muted">{{ informations[simulation.accord]?.text ?? 'Inconnu' }} - Montant: {{ simulation.montant }}, Echéance: {{ simulation.echeance }}</p>
                      </div>
                      <el-tag class="justify-content-center align-items-center my-auto mr-1" type="warning" effect="dark" size="small">Simulation</el-tag>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
            <div class=" d-flex" v-if="this.filter === 'societes' || (this.filter === 'tout' && this.clients)">
              <div class="background-grey d-flex align-items-center justify-content-center info-div info-div-border">
                <p class="text-muted">Raison sociale</p>
              </div>
              <div class="w-100">
                <div class="card d-flex justify-content-center align-items-center text-muted h-100" v-if="!clients">
                  <fa icon="ban" class="mr-2"></fa>
                  Aucune raison sociale créé
                </div>
                <div v-for="client in clients" :key="client.id">
                  <router-link :to="{name:'funding', params:{id :client.id ?? 0}}" @click="closeModal">
                    <div class="card w-100 d-flex">
                      <div class="card-body w-75">
                        <h5 class="font-weight-bolder">#{{ client.code_client }} - {{ client.name }}</h5>
                        <p class="card-text text-muted">{{ client.address }} - {{ client.zip }} {{ client.town }}</p>
                      </div>
                      <el-tag class="d-none justify-content-center align-items-center my-auto mr-1" type="warning" effect="dark" size="small">Client</el-tag>
                      <div class="d-flex justify-content-center align-items-center pr-2">
                        <img class="img-building" src="../assets/img/flat-building.png">
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="d-flex" v-if="this.filter === 'contrats' || (this.filter === 'tout' && (this.contracts && contracts.length))">
              <div class="background-grey d-flex align-items-center justify-content-center info-div info-div-border">
                <p class="text-muted">Contrat</p>
              </div>
              <div class="w-100">
                <div class="card d-flex justify-content-center align-items-center text-muted h-100" v-if="!contracts || !contracts.length">
                  <fa icon="ban" class="mr-2"></fa>
                  Aucun contract trouvé
                </div>
                <div v-for="contract in contracts" :key="contract.id">
                  <router-link :to="{name:'contract',params:{id :contract.client.id,siret:contract.client.idprof2}}"
                               @click="closeModal" v-if="contract">
                    <div class="card w-100 d-flex">
                      <div class="card-body">
                        <h5 class="font-weight-bolder">#{{ contract.reference }} - {{ contract.type_contrat }}</h5>
                        <p class="card-text text-muted">{{ contract.client.name }} - {{ contract.client.address }},
                          {{ contract.client.zip }} {{ contract.client.town }}</p>
                      </div>
                      <div class="d-flex justify-content-center align-items-center pr-2">
                        <img class="img-building" src="../assets/img/flat-building.png">
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mr-4 d-flex align-items-center justify-content-center" v-if="!isMobileOnly()">
        <button @click="this.$router.push({ name: 'tier' })" class="d-flex align-items-center justify-content-center btn btn-purple btn-tier">
          <fa icon="building" class="large mr-2"/>
          Recherche nouvelle raison sociale
        </button>
      </div>
      <div class="d-flex spacing-mobile ml-auto">
        <div class="mr-4 d-flex align-items-center justify-content-center">
          <router-link :to="{name:'dashboard'}" class="d-flex align-items-center justify-content-center" >
            <el-tooltip
                class="box-item"
                effect="light"
                content="Voir mon tableau de bord"
                placement="bottom-start"
            >
            <fa icon="home" class="large"/>
              </el-tooltip>
          </router-link>
        </div>
        <div v-if="isMobileOnly()" class="mr-4 d-flex align-items-center justify-content-center">
          <router-link :to="{ name: 'tier' }" class="d-flex align-items-center justify-content-center">
            <el-tooltip
                class="box-item"
                effect="light"
                content="Rechercher une nouvelle raison sociale"
                placement="bottom-start"
            >
            <fa icon="building" class="large"/>
            </el-tooltip>
          </router-link>
        </div>
        <div class="mr-4 d-flex align-items-center justify-content-center">
          <router-link :to="{ name: 'portfolio' }" class="d-flex align-items-center justify-content-center">
            <el-tooltip
                class="box-item"
                effect="light"
                content="Voir mon portefeuille"
                placement="bottom-start"
            >
              <fa icon="list" class="large"/>
            </el-tooltip>
          </router-link>
        </div>
        <div class="mr-4 d-flex align-items-center justify-content-center">
          <router-link :to="{ name: 'parc' }" class="d-flex align-items-center justify-content-center">
            <el-tooltip
                class="box-item"
                effect="light"
                content="Voir mon parc de contrat"
                placement="bottom-start"
            >
              <fa icon="file-contract" class="large"/>
            </el-tooltip>
          </router-link>
        </div>
        <div class="mr-4 d-flex align-items-center justify-content-center">
          <a class="d-flex align-items-center justify-content-center" href="#">
            <el-popover
                :width="190"
                class="box-item"
                placement="bottom-start">
              <template #reference>
                <fa icon="user" class="large"/>
              </template>
              <p class="user text-center">{{ user }}</p>
              <el-button @click="logOut"><fa icon="sign-out-alt" class="large ml-2"/> Se deconnecter</el-button>
            </el-popover>
          </a>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import _ from 'lodash';
import funding from "@/class/funding";
import {isMobileOnly} from "mobile-device-detect";
import contractService from "@/services/contract.service";

export default {
  name: 'AppHeader',
  data() {
    return {
      query: null,
      client: null,
      contracts: null,
      drop: false,
      loader: false,
      simulations: null,
      clients: null,
      filter: 'tout',
      informations: funding.status,
    }
  },
  watch: {
    // whenever query input changes, this function will run
    query: _.debounce(function(){
      this.search();
    }, 500),

  },
  computed:{
    user: function (){
      let user = this.$store.getters.getUser ?? {name: 'Alain', firstname: 'Connue'};
      return user.firstname + ' ' + user.name;
    }
  },
  methods: {
    isMobileOnly() {
      return isMobileOnly
    },
    checkResult() {
      if (!this.clients && !this.contracts && !this.simulations) {
        this.$notify({
          duration: 5000,
          text: `Aucun Résultat pour ${this.query}`,
          type: 'warn'
        });
      }
    },

    async search(){
      if (this.query?.length >= 3) {
        this.loader = true;
        await this.getResultSearchBar();
        this.openModal();
      } else {
        this.deleteValue();
        this.closeModal();
      }
    },
    logOut() {
      this.$store.commit('setUser', null);
      this.$store.commit('setToken', null);
      this.$store.commit('setContractTypes', null);
      this.$router.push('/login');
    },
    async getCustomersFilters(data) {
      try {
        const res = await this.$http
            .get("/api/thirdparties/", {
              params: {
                sortfield: 't.rowid',
                sortorder: 'ASC',
                limit: 100,
                sqlfilters: `(t.nom:like:'%${data}%') or (t.siret:like:'%${data}%')`
              }
            })

        return res.data;
      }
      catch (e)
      {
        if (e.response.status === 404) {
          return null;
        } else {
          this.$notify({
            duration: 3000,
            text: `Error lors de la recherche des compagnies`,
            type: 'error'
          });
        }
      }
    },
    async getSimulationFilter(data) {
      try {
        const res = await this.$http
            .get("/api/financement/simulation/", {
              params: {
                sortfield: 't.rowid',
                sortorder: 'ASC',
                limit: 100,
                sqlfilters: `(t.reference:like:'%${data}%')`
              }
            })
        return res.data;
      }
      catch (e)
      {
        if (e.response.status === 404) {
          return null;
        } else {
          this.$notify({
            duration: 3000,
            text: `Error lors de la recherche des simulations`,
            type: 'error'
          });
        }
      }
    },
    async getContract(query) {
      const  sqlfilter = `(df.reference:like:'%${query}%')`
      try
      {
        const res = await contractService.getAllContracts(sqlfilter);
        return res.data;
      }
      catch (e)
      {
        if (e.response.status === 404) {
          return null;
        } else {
          this.$notify({
            duration: 3000,
            text: `Error lors de la recherche des contracts`,
            type: 'error'
          });
        }
      }

      return null;
    },
    async getResultSearchBar() {
      this.clients = await this.getCustomersFilters(this.query);
      this.simulations = await this.getSimulationFilter(this.query);
      this.contracts = await this.getContract(this.query);
      this.checkResult();
      this.loader = false
    },
    openModal() {
      if (this.query?.length >= 3) {
        this.drop = true;
      }
    },
    closeModal() {
      this.drop = false;
    },

    deleteValue() {
      this.contract = null;
      this.clients = null;
      this.simulations = null;
    }
  }
}
</script>

<style scoped>

.user{
  font-weight: 700;
}

@media screen and (max-width: 767px) {
  .spacing-mobile {
    padding-top: 0.5rem;
  }
  .search-main .main-input {
    max-width: 300px;
  }
}

@media screen and (max-width: 639px) {
  .spacing-mobile {
    order: 2;
  }
}

@media screen and (min-width: 640px) {
  .search-main {
    width: -webkit-fill-available;
    max-width: 430px;
  }

}

.input-group {
  width: auto
}

.input-group-prepend .input-group-text {
  background: #fff;
}

.form-control {
  border-radius: 0;
  font-size: 1em;
  color: #a9a9a9 !important;
}

.btn-light-red .spinner-border {
  width: 1rem;
  height: 1rem;
}

.img-building{
  margin-left: 10px;
  width: 30px;
}


.large{
  font-size: large;
}

.text-muted, .text-muted::placeholder {
  color: #a9a9a9 !important;
}

.main-menu a{

}


.btn {
  color: #fff;
}

.containerSearch {
  background: #ffffff;
  z-index: 9999;
  left: -5px;
  right: -5px;
  border-radius: 3px;
  top: 5px;
  max-height: 350px;
  overflow-y: auto;
  border: 1px solid #CCCCCC;
}


.background-grey {
  background: rgba(0, 0, 0, .125);
}

.card {
  border-radius: 0 !important;
  flex-direction: row !important;
  border-right: none;
  border-left: none;
  border-top: none;
}

.card-body {
  padding: 0.75em;
}

.logo-k{
  height: 30px;
  margin-right: 10px;
}

.search-main .main-input {
  width: 250px;
}

.clickOut {
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  background: transparent;
  height: 100vh;
  z-index: 999;
}
.btn-light-red{
  background: lightcoral;
}

.card{
  max-height: none;
  min-height: 50px;
}

.info-div{
  width: 150px;
}

.info-div-border{
  border-bottom: 1px solid rgba(0,0,0,.125)
}

.navbar-dark .navbar-brand{
  font-weight: 800;
}

.searchButton{
  max-width: 600px !important;
}

a{
  color: white;
}

a:hover{
  color: #ACACAC;
}

.btn-tier{
  color: #6e398e !important;
}

.btn-tier:hover{
  background-color: #9569ab;
  color: white !important;
}
</style>