<template>
  <div class="row">
    <div class="form-group btn-action col-lg-6 col-12 text-center d-none">
      <el-popover
          placement="top-start"
          :width="200"
          trigger="hover"
          content="Générer un devis et télécharger un devis"
      >
        <template #reference>
          <button class="btn btn-warning cursor-pointer" type="button" @click="this.emitOption('sendDevis');">
            <span class="ifa" v-if="loadingDevis"><fa icon="circle-notch" class="fa-spin"></fa></span>
            <span class="ifa" v-else><fa icon="fa-envelope-open-text"></fa></span>
            Devis
          </button>
        </template>
      </el-popover>
    </div>
    <div class="form-group btn-action col-lg-6 col-12 text-center">
      <el-popover placement="top-start" :width="250" :visible="showPopUp">
        <h5 :style="{ color: this.statusFunding[this.status]?.colorhex }" class="initial-align break">
          Votre simulation est en {{ this.statusFunding[this.status]?.text }}.
        </h5>
        <p class="initial-align break">Vous pouvez actuliser votre demande avec les nouvelles informations saisies</p>
        <button class="btn btn-primary max-content mt-2" v-on:click="this.$router.go();">Abondonner les modifications</button>
        <template #reference>
          <template v-if="modified && statusIsValid">
            <button
                class="btn btn-warning cursor-default"
                type="button"
                @click="
                  this.emitOption('sendFunding');
                "
            >
              <span class="ifa white"><fa icon="circle-notch"></fa></span>
              <span class="long white">Envoyer<br />les modifications</span>
            </button>
          </template>
          <template v-else>
            <el-popover v-if="this.status === 'WAIT' || this.status === 'WAIT_MODIF'"
                        placement="top-start"
                        :width="200"
                        trigger="hover"
                        content="Simulation en étude"
            >
              <template #reference>
                <button class="btn btn-blue cursor-pointer" type="button">
                  <span class="ifa" v-if="loadingState"><fa icon="circle-notch" class="fa-spin"></fa></span>
                  <span class="ifa" v-else><fa icon="stopwatch"></fa></span>
                  En étude
                </button>
              </template>
            </el-popover>
            <el-popover v-else-if="this.status === 'WAIT_LEASER'"
                        placement="top-start"
                        :width="200"
                        trigger="hover"
                        content="En attente d'éléments"
            >
              <template #reference>
                <button class="btn btn-warning cursor-default" type="button">
                  <span class="ifa"><fa icon="question-circle"></fa></span>
                  En attente
                </button>
              </template>
            </el-popover>
            <el-popover v-else-if="this.status === 'WAIT_AP'" @click="this.emitOption('sendFunding');"
                        placement="top-start"
                        :width="200"
                        trigger="hover"
                        content="Accord de principe"
            >
              <template #reference>
                <button class="btn btn-success cursor-default" type="button">
                  <span class="ifa"><fa icon="handshake"></fa></span>
                  Accord
                </button>
              </template>
            </el-popover>
            <el-popover v-else-if="statusIsValid" @click="this.emitOption('sendFunding');"
                        placement="top-start"
                        :width="200"
                        trigger="hover"
                        content="Simulation en Accord"
            >
              <template #reference>
                <button class="btn btn-success cursor-default" type="button">
                  <span class="ifa"><fa icon="thumbs-up"></fa></span>
                  Accord
                </button>
              </template>
            </el-popover>
            <el-popover v-else-if="this.status === 'KO'"
                        placement="top-start"
                        :width="200"
                        trigger="hover"
                        content="Simulation Refusée"
            >
              <template #reference>
                <button class="btn btn-danger cursor-default" type="button">
                  <span class="ifa"><fa icon="ban"></fa></span>
                  Refus
                </button>
              </template>
            </el-popover>
            <el-popover v-else-if="this.status === 'OK_EXP'"
                        placement="top-start"
                        :width="200"
                        trigger="hover"
                        content="Simulation expirée"
            >
              <template #reference>
                <button class="btn btn-grey cursor-default" type="button" >
                  <span class="ifa"><fa icon="ban"></fa></span>
                  Expirée
                </button>
              </template>
            </el-popover>
            <el-popover v-else
                        placement="top-start"
                        title="Envoyer"
                        :width="200"
                        trigger="hover"
                        content="Envoyer la simulation"
            >
              <template #reference>
                <button ref="popoverRef" class="btn btn-blue cursor-pointer" type="button" :disabled="loadingState" @click="this.emitOption('sendFunding');">
                  <span class="ifa" v-if="loadingState"><fa icon="circle-notch" class="fa-spin"></fa></span>
                  <span class="ifa" v-else><fa icon="paper-plane"></fa></span>
                  Envoyer
                </button>
              </template>
            </el-popover>
          </template>
        </template>
      </el-popover>
    </div>
  </div>
</template>

<script>
import Funding from "@/class/funding";
import statusFunding from "@/class/funding";
import {ref} from "vue";

export default {
  name: 'FundingActions',
  props: {
    loadingDevis:{
      type: Boolean
    },
    status: {
      type: String,
      default: 'NEW'
    },
    modified: {
      type: Boolean
    },
    modifiable: {
      type: Boolean
    },
    loadingState: {
      type: Boolean
    }
  },
  data() {
    return {
      popoverRef: ref(),
      statusFunding: statusFunding.status
    };
  },
  computed: {
    statusIsValid() {
      return Funding.validStatus.includes(this.status);
    },
    showPopUp() {
      return this.modified && (this.status === 'WAIT_AP' || this.statusIsValid);
    }
  },
  methods: {
    emitOption(method, name = null, value = null) {
      if (this.modifiable) {
        this.$emit(method, name, value);
      }
    },
  }
};
</script>

<style scoped>
.btn-action .btn {
  position: relative;
}

.btn-action .btn {
  background-color: #1e3e51;
  border-radius: 6px;
  margin-left: 20px;
  padding: 15px 20px 20px 40px;
  height: 100%;
  color: #fff;
  min-width: 220px;
  font-size: 1.7em;
}

.btn-action .btn.btn-warning {
  background-color: #bb9000;
}

.btn-action .btn.btn-grey {
  background-color: #808080;
}

.btn-action .btn.btn-success {
  background-color: #028129;
}

.btn-action .btn.btn-danger {
  background-color: #810202;
}

.btn-action .btn .long {
  font-size: 0.75em;
  display: inline-block;
}

.btn-action .btn span.ifa {
  background-color: #ab88bc;
  display: inline-block;
  border-radius: 6px;
  position: absolute;
  left: 0;
  top: 0;
  padding: 15px 20px;
  font-size: 1em;
  height: 100%;
}

.btn-action .btn.btn-warning span.ifa {
  background-color: #a1681d;
}

.btn-action .btn.btn-success span.ifa {
  background-color: #1da142;
}

.btn-action .btn.btn-danger span.ifa {
  background-color: #a11d1d;
}

.btn-action .btn.btn-grey span.ifa{
  background-color: #a0a0a0;
}

.btn.btn-blue {
  background-color: #9569ab;
}

button .ifa {
  color: white;
}
</style>
