<template>
  <form v-if="show" id="testlaBar" class="row">
    <div class="form-group select-focus tesla col">
      <p><label>Type Contrat</label></p>
      <select
        class="form-control t-1-4e"
        @change="emitOption('recalculateFromTeslaChanges', 'fk_type_contrat', $event.target.value)"
        :disabled="!modifiable"
      >
        <option v-for="(object, key) in typeContrat" :key="key" :selected="key === funding.fk_type_contrat" :value="key">{{ object.label }}</option>
      </select>
    </div>
    <div class="form-group select-focus dropdown col">
      <p><label>Durée</label></p>
      <select
        class="form-control"
        @change="emitOption('recalculateFromTeslaChanges', 'duree', parseFloat($event.target.value))"
        v-model="funding.duree"
        :disabled="!modifiable"
      >
        <option v-for="num in duration" :key="num" :value="num" :selected="num === parseFloat(funding.duree)">{{ num }}</option>
      </select>
    </div>
    <div class="form-group select-focus dropdown col">
      <p><label>Période</label></p>
      <select class="form-control" @change="changePeriodicity($event.target.value)" :disabled="!modifiable">
        <option v-for="(name, value) in contractPeriodicity" :selected="value === funding.opt_periodicite" :key="value" :value="value">
          {{ name }}
        </option>
      </select>
    </div>
    <div class="form-group focus tesla col">
      <p><label>Montant financé HT</label></p>
      <input
          type="number"
          class="form-control"
          placeholder="0.00"
          @blur="debounceInput('montant_total_finance', parseFloat($event.target.value))"
          :value="(Math.round(funding.montant_total_finance * 100) / 100).toFixed(2)"
          :readonly="!modifiable"
      />
    </div>
    <div class="form-group focus tesla col">
      <p><label>Loyer HT</label></p>
      <input type="number" class="form-control loyer" placeholder="0.00"
             @blur="debounceInput('echeance', parseFloat($event.target.value))"
             :value="(this.funding.echeance).toFixed(2)"
             :readonly="!modifiable"/>
    </div>
  </form>
</template>

<script>
import statusFunding from '../class/funding.js';
import Funding from "@/class/funding";

export default {
  name: 'TeslaBar',
  props: {
    fundingProps: {
      type: Object
    },
    typeContrat: {
      type: Array
    },
    status: {
      type: String,
      default: 'NEW'
    },
    duration: {
      type: Object
    },
    contractPeriodicity: {
      type: Object
    },
    modified: {
      type: Boolean
    },
    modifiable: {
      type: Boolean
    },
    loadingState: {
      type: Boolean
    }
  },
  data() {
    return {
      funding: null,
      echeance: null,
      default_montant_total_finance: 0,
      show: false,
      statusFunding: statusFunding.status
    };
  },
  watch: {
    fundingProps(newValue) {
      this.funding = newValue;
    }
  },
  methods: {
    emitOption(method, name = null, value = null) {
      if (this.modifiable) {
        this.$emit(method, name, value);
      }
    },
    debounceInput(field, value) {
      this.emitOption('recalculateFromTeslaChanges', field, value);
    },
    changePeriodicity(value) {
      this.$emit('recalculateFromTeslaChanges', 'opt_periodicite_wait', value);
    },
    raz() {
      this.funding.montant_total_finance = this.default_montant_total_finance;
    }
  },
  computed: {
    statusIsValid() {
      return Funding.validStatus.includes(this.status);
    },
    showPopUp() {
      return this.modified && (this.status === 'WAIT_AP' || this.statusIsValid);
    }
  },
  mounted() {
    this.funding = this.fundingProps;
    this.echeance = this.funding.echeance;
    this.show = true;
  }
};
</script>

<style scoped>
.label-right {
  display: block;
  text-align: right !important;
}
#testlaBar {
  background: #464b5f;
  color: #fff;
  margin: 10px 0 !important;
  padding: 0px !important;
}

.form-control:disabled {
  background-color: transparent;
}

.tesla p {
  margin: 0 !important;
}

.dropdown p {
  margin: 0 !important;
}

.tesla input {
  color: #464b5f !important;
}

.tesla input:read-only {
  color: white;
}

.tesla input {
  max-width: 200px;
}

.form-group {
  height: 70px;
  padding: 0 12px;
}

.form-group label {
  font-weight: 700;
  color: white;
}

.form-group.focus {
  border-radius: 6px;
  min-width: 200px;
}

.form-group.select-focus {
  border-radius: 6px;
  min-width: 100px;
}

.form-group.focus input{
  width: fit-content;
}

select,
select:active,
select:focus,
input[type='text'] {
  color: white;
  border: 0;
}

select input,
select:active,
select:focus,
input[type='text'] {
  color: white;
  border: 0;
}

input[type='text'] {
  font-size: 1.5em;
  height: 1.8em;
}

select,
select:active,
select:focus {
  background: transparent;
  cursor: pointer;
}

select option {
  color: #fff;
  background-color: #082c42;
  margin: 0;
  cursor: pointer;
  font-size: 16px;
  padding: 0;
}

.form-inline label {
  justify-content: flex-start;
  text-align: left;
  font-size: 1.3em;
  padding-bottom: 5px;
}

#testlaBar .form-group{
  margin: 20px !important;
}

</style>
