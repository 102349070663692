<template>
  <div class="w-75 m-auto">
    <nav class="navbar navbar-expand-lg">
      <div class="navbar-collapse">
        <div class="navbar-nav mr-auto">
          <router-link :to="{ name: 'parc' }" class="nav-link t-20">
            <h3>Parc de contrat</h3>
          </router-link>
        </div>
      </div>
      <div class="m-auto text-center d-none">
        <a class="btn btn-purple-inverted m-1" @click="exportParc">
          <fa class="fa" icon="file-export"></fa> Génerer le parc de contrats
        </a>
        <a v-if="currentFile" class="btn btn-purple-inverted m-1" :href="currentFile" :download="'parcContrat_'+new Date()">
          <fa class="fa" icon="file-export"></fa> Télécharger le parc de contrats
        </a>
      </div>
    </nav>
    <hr style="margin-top: 10px; border-top: 2px solid lightgray"/>
    <div class="row">
      <div class="contract col-lg-2 col-md-12">
        <h4>Filtre <span v-if="activeFilter">({{activeFilter}} actif)</span></h4>
        <el-collapse>
          <el-collapse-item title="Reference" name="1">
            <div>
              <h6>Référence contract</h6>
              <el-input class="input-group" v-model="filter.reference"></el-input>
            </div>
          </el-collapse-item>
          <el-collapse-item title="Date" name="2" class="d-none">
            <div class="mb-3">
              <h6>Date de début</h6>
              <el-date-picker
                  class="m-1"
                  v-model="filter.start.dateDebut"
                  type="date"
                  format="DD-MM-YYYY"
                  value-format="YYYY-MM-DD"
              />
              <el-date-picker
                  class="m-1"
                  v-model="filter.start.dateFin"
                  type="date"
                  format="DD-MM-YYYY"
                  value-format="YYYY-MM-DD"
              />
            </div>
            <div>
              <h6>Date de fin</h6>
              <el-date-picker
                  class="m-1"
                  v-model="filter.end.dateDebut"
                  type="date"
                  format="DD-MM-YYYY"
                  value-format="YYYY-MM-DD"
                  placeholder=""
              />
              <el-date-picker
                  class="m-1"
                  v-model="filter.end.dateFin"
                  type="date"
                  format="DD-MM-YYYY"
                  value-format="YYYY-MM-DD"
              />
            </div>
          </el-collapse-item>
          <el-collapse-item title="Statuts" name="3">
            <div class="mb-3">
              <h6>Reconduction</h6>
              <div v-for="status in reconductionStatut.status" :key="status.id">
                <el-checkbox v-model="filter.reconduction" :value="status.status" :label="status.label"></el-checkbox>
              </div>
            </div>
            <div>
              <h6>Paiement</h6>
              <el-select v-model="filter.paiement">
                <el-option v-for="status in paiementStatus.status" :value="status.sqlfilter" :label="status.label" :key="status.id"></el-option>
              </el-select>
            </div>
          </el-collapse-item>
        </el-collapse>
        <el-button class="m-2 btn btn-purple" @click="resetFilter()" >Réinitialiser filtre</el-button>
        <el-button class="m-2 btn btn-purple-inverted" @click="fetchContratFilter()" >Rechercher</el-button>
      </div>
      <DataTableContract class="col-lg-9 col-md-12" :loading="loading" :contracts="contracts"></DataTableContract>
    </div>
  </div>
</template>

<script>

import DataTableContract from "@/components/DataTableContract.vue";
import contractService from "@/services/contract.service";
import ReconductionStatut from "@/class/ReconductionStatut";
import { base64ToBlob } from "@/helpers/tools";
import PaiementStatut from "@/class/PaiementStatut";

export default {
  name: 'Parc',
  components: {DataTableContract},
  data() {
    return {
      paiementStatus: PaiementStatut,
      contracts: [],
      loading: false,
      currentTime : Date.now(),
      currentFile: null,
      reconductionStatut: ReconductionStatut,
      filter: {
        reference: null,
        start: {
          dateFin: null,
          dateDebut: null,
        },
        end: {
          dateFin: null,
          dateDebut: null,
        },
        paiement: null,
        reconduction: []
      }
    };
  },
  computed: {
    activeFilter(){
      return Object.values(this.filter).filter((proprety) => {
        return proprety !== null && proprety !== '' && proprety.length > 0;
      }).length
    },

  },
  methods: {
    resetFilter(){
      this.filter = {
        'reference': null,
        'dateFin': null,
        'dateDebut': null,
        'paiement': null,
        'reconduction': []
      }
      this.getContract()
    },
    async fetchContratFilter(){
      this.loading = true;
      let sqlfilter = ''
      if(this.filter.reference !== '' && this.filter.reference !== null){
        sqlfilter += `(df.reference:like:'%${this.filter.reference}%')`
      }
      if(this.filter.end.dateDebut !== null && this.filter.end.dateDebut !== ''){
        sqlfilter = this.addOperator(sqlfilter)
        sqlfilter += `(df.date_fin>='${this.filter.end.dateDebut}')`
      }
      if(this.filter.end.dateFin !== null && this.filter.end.dateFin !== ''){
        sqlfilter = this.addOperator(sqlfilter)
        sqlfilter += `(df.date_fin<='${this.filter.end.dateFin}')`
      }
      if(this.filter.start.dateDebut !== null && this.filter.start.dateDebut !== ''){
        sqlfilter = this.addOperator(sqlfilter)
        sqlfilter += `(df.date_debut>='${this.filter.start.dateDebut}')`
      }
      if(this.filter.start.dateFin !== null && this.filter.start.dateFin !== ''){
        sqlfilter = this.addOperator(sqlfilter)
        sqlfilter += `(df.date_debut<='${this.filter.start.dateFin}')`
      }
      if(this.filter.paiement !== null && this.filter.paiement !== ''){
        sqlfilter = this.addOperator(sqlfilter)
        sqlfilter += this.filter.paiement
      }
      await this.getContract(sqlfilter);
      this.dataFilter();
    },
    dataFilter(){
      if(this.filter.reconduction.length){
        this.contracts = this.contracts.filter((contract) => {
          return this.filter.reconduction.includes(contract.reconductible.status);
        })
      }
    },
    addOperator(sqlfilter){
      if(sqlfilter !== ''){
        sqlfilter += ' AND ';
      }
      return sqlfilter
    },
    exportParc(){
      contractService.exportAllContract().then((response) => {
        this.$notify({
          duration: 5000,
          text: 'Votre document est en cours de préparation',
          type: 'success'
        });
        let blob = base64ToBlob(response.data.content);
        this.currentFile = window.URL.createObjectURL(new File([blob], 'file', {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));

      }).catch((error) => {
        this.$notify({
          duration: 5000,
          text: error,
          type: 'error'
        });
      });
    },
    preCalculData(contracts){
      contracts.forEach((contract) => {
        contract.pourcentage_avancement = this.calculPourcentageDate(contract);
        contract.reconductible = this.checkReconductible(contract);
      })
      return contracts;
    },
    calculPourcentageDate(contract){
      if(this.currentTime > contract.date_fin){
        return 100;
      }
      if(this.currentTime < contract.date_debut){
        return 0;
      }
      return Math.round((this.currentTime - contract.date_debut) /  (contract.date_fin - contract.date_debut) * 100);
    },
    checkReconductible(contract){
      if(!contract.financement.taciteReconduction){
        return ReconductionStatut.status.find((status) => {
          return status.status === 'arret'
        })
      }
      if(contract.financement.taciteReconduction && contract.date_fin < this.currentTime){
        return ReconductionStatut.status.find((status) => {
          return status.status === 'reconduit'
        })
      }
      if(contract.financement.taciteReconduction && contract.date_fin > this.currentTime){
        return ReconductionStatut.status.find((status) => {
          return status.status === 'reconductible'
        })
      }
      return 0;
    },
    async getContract(sqlfilter = ''){
      await contractService.getAllContracts(sqlfilter)
          .then((response) => {
            this.contracts = this.preCalculData(response.data)
          }).catch(() => {
            this.$notify({
              duration: 5000,
              text: 'Erreur lors de la récupération des contracts',
              type: 'error'
            })
          }).finally(() => {
            this.loading = false;
          })
    }
  },
  async mounted() {
    this.currentTime = Math.round(this.currentTime / 1000)
    this.loading = true;
    await this.getContract();
  }
};
</script>

<style scoped>
h3{
  text-transform: uppercase;
  font-weight: 700;
}

</style>
